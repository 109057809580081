<template>
  <base-material-card id="Invoices-list" icon="">
    <v-tabs v-model="filters.invoice_type">
      <v-tab :href="`#${tab}`" v-for="(tab, i) in tabs" :key="i">{{
        $t(tab)
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="filters.invoice_type">
      <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="tab">
        <v-row
          align="center"
          class="pa-md-3"
          v-if="tab == filters.invoice_type"
        >
          <v-col cols="6" md="2" class="py-0">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filters.search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
            ></v-text-field>
          </v-col>

          <v-col cols="4" md="2" class="py-0">
            <v-select
              :label="$t('invoices.type')"
              v-model="filters.type"
              :items="types"
              outlined
              dense
              clearable
              hide-details
            >
              <template v-slot:selection="{ item }">
                {{ $t("invoices.types." + item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $t("invoices.types." + item) }}
              </template>
            </v-select>
          </v-col>
          <v-menu v-if="$vuetify.breakpoint.xs">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" class="mr-4">
                <v-icon size="36">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  $router.push({
                    name: 'InvoicesNewForm',
                    params: { invoice_type: tab },
                  })
                "
              >
                <v-list-item-title>{{
                  $t("new", { name: $t(tab) })
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$refs[`taxes_${tab}`][0].dialog = true">
                <v-list-item-icon class="my-1"
                  ><v-icon>mdi-plus</v-icon></v-list-item-icon
                >
                <v-list-item-title>
                  {{ $tc("invoices.tax", 2) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openMultiple(i)" v-if="tab == 'invoice'">
                <v-list-item-icon class="my-1"
                  ><v-icon>mdi-plus</v-icon></v-list-item-icon
                >
                <v-list-item-title>
                  {{ $t("invoices.multiple") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-col cols="6" md="2" class="py-0">
            <v-menu
              v-model="menu.first_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$t('from')"
                  class="daymenu"
                  outlined
                  dense
                  hide-details
                  :value="
                    filters.from
                      ? $d(new Date(filters.from), 'date2digits')
                      : null
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-bind:class="{ 'error--text': filters.from > filters.to }"
                  clearable
                  @click:clear="filters.from = null"
                  prepend-inner-icon="$appointments"
                >
                  <template v-slot:prepend-inner> </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="filters.from"
                first-day-of-week="1"
                no-title
                color="primary"
                :max="filters.to"
                @input="menu.first_day = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="2" class="py-0">
            <v-menu
              v-model="menu.last_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$t('to')"
                  class="daymenu"
                  outlined
                  dense
                  hide-details
                  :value="
                    filters.to ? $d(new Date(filters.to), 'date2digits') : null
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-bind:class="{ 'error--text': filters.from > filters.to }"
                  clearable
                  @click:clear="filters.to = null"
                  prepend-inner-icon="$appointments"
                >
                  <template v-slot:prepend-inner> </template>
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="filters.to"
                color="primary"
                no-title
                :min="filters.from"
                @input="menu.last_day = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-spacer></v-spacer>
          <Taxes :ref="`taxes_${tab}`" />
          <Multiple
            :ref="`multiple_${tab}`"
            @generated="$refs[`table_${tab}`][0].fetchInvoices()"
          />
          <v-menu v-if="$vuetify.breakpoint.smAndUp">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" class="mr-4">
                <v-icon size="36">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  $router.push({
                    name: 'InvoicesNewForm',
                    params: { invoice_type: tab },
                  })
                "
              >
                <v-list-item-icon class="mt-2"
                  ><v-icon small>$invoice</v-icon></v-list-item-icon
                >
                <v-list-item-title>{{
                  $t("new", { name: $t(tab) })
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$refs[`taxes_${tab}`][0].dialog = true">
                <v-list-item-icon class="my-1"
                  ><v-icon>mdi-plus</v-icon></v-list-item-icon
                >
                <v-list-item-title>
                  {{ $tc("invoices.tax", 2) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openMultiple" v-if="tab == 'invoice'">
                <v-list-item-icon class="my-1"
                  ><v-icon>mdi-plus</v-icon></v-list-item-icon
                >
                <v-list-item-title>
                  {{ $t("invoices.multiple") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <Table :filters="filters" :ref="`table_${tab}`" />
      </v-tab-item>
    </v-tabs-items>
  </base-material-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "InvoicesList",
  data() {
    return {
      selectedUrl: "",
      items: [],
      tabs: ["invoice", "proforma", "budged"],
      headers: [
        {
          text: this.$t("invoices.number"),
          sortable: false,
          value: "number",
          align: "center",
        },
        {
          text: this.$t("invoices.date"),
          sortable: false,
          value: "date",
          align: "center",
        },
        {
          text: this.$t("invoices.type"),
          sortable: false,
          value: "type",
          align: "center",
        },
        {
          text: this.$t("invoices.from"),
          sortable: false,
          value: "from",
          align: "center",
        },
        {
          text: this.$t("invoices.to"),
          sortable: false,
          value: "to",
          align: "center",
        },
        {
          text: this.$t("invoices.total"),
          sortable: false,
          value: "total_price",
          align: "center",
        },
        /*
        {
          text: this.$t("payment_method"),
          sortable: false,
          value: "payment_method",
          align: "center",
        },
        */
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
          align: "center",
        },
      ],
      options: {},
      filters: {
        invoice_type: "invoice",
      },
      total: 0,
      menu: {
        first_day: false,
        last_day: false,
      },
      types: ["appointment", "custom", "expense", "inventory"],
    };
  },
  components: {
    Taxes: () => import("@/components/invoices/taxes/List"),
    Table: () => import("@/components/invoices/Table"),
    Multiple: () => import("@/components/invoices/GenerateMultiple"),
  },
  methods: {
    openMultiple() {
      console.log(this.$refs[`multiple_${this.filters.invoice_type}`][0]);
      console.log(`multiple_${this.filters.invoice_type}`);
      this.$refs[`multiple_${this.filters.invoice_type}`][0].open();
    },
  },
};
</script>

<style lang="sass">
.daymenu
  .v-input__icon.v-input__icon--prepend-inner
    min-width: unset !important
    width: 20px !important
</style>
