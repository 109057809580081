var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"id":"Invoices-list","icon":""}},[_c('v-tabs',{model:{value:(_vm.filters.invoice_type),callback:function ($$v) {_vm.$set(_vm.filters, "invoice_type", $$v)},expression:"filters.invoice_type"}},_vm._l((_vm.tabs),function(tab,i){return _c('v-tab',{key:i,attrs:{"href":("#" + tab)}},[_vm._v(_vm._s(_vm.$t(tab)))])}),1),_c('v-tabs-items',{model:{value:(_vm.filters.invoice_type),callback:function ($$v) {_vm.$set(_vm.filters, "invoice_type", $$v)},expression:"filters.invoice_type"}},_vm._l((_vm.tabs),function(tab,i){return _c('v-tab-item',{key:i,attrs:{"value":tab}},[(tab == _vm.filters.invoice_type)?_c('v-row',{staticClass:"pa-md-3",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('search')},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"2"}},[_c('v-select',{attrs:{"label":_vm.$t('invoices.type'),"items":_vm.types,"outlined":"","dense":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("invoices.types." + item))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("invoices.types." + item))+" ")]}}],null,true),model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'InvoicesNewForm',
                  params: { invoice_type: tab },
                })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("new", { name: _vm.$t(tab) })))])],1),_c('v-list-item',{on:{"click":function($event){_vm.$refs[("taxes_" + tab)][0].dialog = true}}},[_c('v-list-item-icon',{staticClass:"my-1"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$tc("invoices.tax", 2))+" ")])],1),(tab == 'invoice')?_c('v-list-item',{on:{"click":function($event){return _vm.openMultiple(i)}}},[_c('v-list-item-icon',{staticClass:"my-1"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("invoices.multiple"))+" ")])],1):_vm._e()],1)],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"daymenu",class:{ 'error--text': _vm.filters.from > _vm.filters.to },attrs:{"label":_vm.$t('from'),"outlined":"","dense":"","hide-details":"","value":_vm.filters.from
                    ? _vm.$d(new Date(_vm.filters.from), 'date2digits')
                    : null,"readonly":"","clearable":"","prepend-inner-icon":"$appointments"},on:{"click:clear":function($event){_vm.filters.from = null}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return undefined},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu.first_day),callback:function ($$v) {_vm.$set(_vm.menu, "first_day", $$v)},expression:"menu.first_day"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","color":"primary","max":_vm.filters.to},on:{"input":function($event){_vm.menu.first_day = false}},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"daymenu",class:{ 'error--text': _vm.filters.from > _vm.filters.to },attrs:{"label":_vm.$t('to'),"outlined":"","dense":"","hide-details":"","value":_vm.filters.to ? _vm.$d(new Date(_vm.filters.to), 'date2digits') : null,"readonly":"","clearable":"","prepend-inner-icon":"$appointments"},on:{"click:clear":function($event){_vm.filters.to = null}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return undefined},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu.last_day),callback:function ($$v) {_vm.$set(_vm.menu, "last_day", $$v)},expression:"menu.last_day"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","color":"primary","no-title":"","min":_vm.filters.from},on:{"input":function($event){_vm.menu.last_day = false}},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)],1),_c('v-spacer'),_c('Taxes',{ref:("taxes_" + tab),refInFor:true}),_c('Multiple',{ref:("multiple_" + tab),refInFor:true,on:{"generated":function($event){_vm.$refs[("table_" + tab)][0].fetchInvoices()}}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                  name: 'InvoicesNewForm',
                  params: { invoice_type: tab },
                })}}},[_c('v-list-item-icon',{staticClass:"mt-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$invoice")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("new", { name: _vm.$t(tab) })))])],1),_c('v-list-item',{on:{"click":function($event){_vm.$refs[("taxes_" + tab)][0].dialog = true}}},[_c('v-list-item-icon',{staticClass:"my-1"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$tc("invoices.tax", 2))+" ")])],1),(tab == 'invoice')?_c('v-list-item',{on:{"click":_vm.openMultiple}},[_c('v-list-item-icon',{staticClass:"my-1"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("invoices.multiple"))+" ")])],1):_vm._e()],1)],1):_vm._e()],1):_vm._e(),_c('Table',{ref:("table_" + tab),refInFor:true,attrs:{"filters":_vm.filters}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }